$(document).ready(function() {
	
  $('.login-form__input input').focus(function(){
    $(this).parent().addClass('focus');
  });

  $('.login-form__input input').focusout(function(){
    console.log($(this).val().length)

    if($(this).val().length <= 0){
      $(this).parent().removeClass('fill');
      $(this).parent().removeClass('focus');
    }else{
      $(this).parent().addClass('fill');
    }
    
  });

  $('header .btn-menu').click(function(){
    $('.header__content-menu').addClass('open');
    $('header').addClass('closed');
    $('.bg-shadow').show();
  });

  $('.header__content-menu .btn-close').click(function(){
    $('.header__content-menu').removeClass('open');
    $('header').removeClass('closed');
    $('.bg-shadow').hide();
  });

  $('.header__content-menu a').click(function(){
    $('.header__content-menu').removeClass('open');
    $('header').removeClass('closed');
    $('.bg-shadow').hide();
  });

  $('.bg-shadow').click(function(){
    $('.header__content-menu').removeClass('open');
    $('header').removeClass('closed');
    $('.bg-shadow').hide();
  });

  $(window).scroll(function() {    
    var scroll = $(window).scrollTop();

    if (scroll >= 52) {
        $("header").addClass("scroll");
    }else {
        $("header").removeClass("scroll");
    }
  });

});
